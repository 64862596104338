import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
	const currentYear = new Date().getFullYear()

	const footerStyle = {
		width: '100%',
		backgroundColor: '#030712',
		borderTop: '1px solid gray',
		color: '#fff',
		padding: '2rem 0',
		marginTop: 'auto',
	}

	const containerStyle = {
		maxWidth: '1200px',
		margin: '0 auto',
		padding: '0 1rem',
	}

	const flexContainerStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '1rem',
	}

	const navStyle = {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		gap: '1rem',
	}

	const linkStyle = {
		color: '#fff',
		textDecoration: 'none',
		transition: 'color 0.3s ease',
		fontWeight: '200',
	}

	const emailStyle = {
		display: 'flex',
		alignItems: 'center',
		gap: '0.5rem',
	}

	const copyrightStyle = {
		marginTop: '2rem',
		textAlign: 'center',
		fontSize: '0.875rem',
		color: '#888',
	}

	const handleLinkHover = (e, isHovering) => {
		e.target.style.color = isHovering ? '#ccc' : '#fff'
	}

	return (
		<footer style={footerStyle}>
			<div style={containerStyle}>
				<div style={flexContainerStyle}>
					<nav style={navStyle}>
						<a
							href="#"
							style={linkStyle}
							onMouseOver={e => handleLinkHover(e, true)}
							onMouseOut={e => handleLinkHover(e, false)}>
							Home
						</a>
						<Link to="/polityka-prywatnosci">Polityka Prywatności</Link>
						<a
							href="#"
							style={linkStyle}
							onMouseOver={e => handleLinkHover(e, true)}
							onMouseOut={e => handleLinkHover(e, false)}>
							Regulamin
						</a>
					</nav>
					<div style={emailStyle}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round">
							<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
							<polyline points="22,6 12,13 2,6"></polyline>
						</svg>
						<a
							href="mailto:contact@example.com"
							style={linkStyle}
							onMouseOver={e => handleLinkHover(e, true)}
							onMouseOut={e => handleLinkHover(e, false)}>
							titanmethodebook@gmail.com
						</a>
					</div>
				</div>
				<div style={copyrightStyle}>© {currentYear} BLONEKDEV.</div>
			</div>
		</footer>
	)
}
