import React from 'react'
import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceLaughWink, faGift, faStar, faDollarSign, faBook } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/footer/footer'

import { ReactComponent as MySVG } from '../assets/GraduationHat.svg'
import HeroIMG from '../assets/Height/333.png'
import pfp1 from '../assets/pfp/1.jpg'
import pfp2 from '../assets/pfp/2.jpg'
import pfp3 from '../assets/pfp/3.jpg'
import pfp4 from '../assets/pfp/4.jpg'
import pfp5 from '../assets/pfp/5.jpg'

import review1 from '../assets/review-ss/review-1.png'
import review2 from '../assets/review-ss/review-2.png'
import review3 from '../assets/review-ss/review-3.png'
import review4 from '../assets/review-ss/review-4.png'
import review5 from '../assets/review-ss/review-5.png'
import review6 from '../assets/review-ss/review-6.png'
import review7 from '../assets/review-ss/review-7.png'
import { useNavigate } from 'react-router-dom'

function Home() {
	const navigate = useNavigate()

	const handlePurchase = amount => {
		localStorage.setItem('selectedAmount', amount) // Store amount in localStorage
		navigate('/info') // Redirect to InfoPage
	}

	return (
		<>
			<div className="subheader">
				<h6>Zniżka -70%</h6>
			</div>

			<header>
				<div className="text-hero">
					<h1>
						<b>
							Wykorzystaj <span>100%</span>
						</b>
						<br /> Potencjału Swojego Wzrostu!{' '}
					</h1>
					<p>Poznaj skuteczne ćwiczenia, które pomogły wielu osobom zyskać dodatkowe centymetry i poprawić postawę.</p>
					<div className="form-CTA">
						<a href="#pricing">
							{' '}
							<button>
								<FontAwesomeIcon icon={faFaceLaughWink} /> Odbierz poradnik!
							</button>
						</a>
						<p className="button-info">
							<span>
								<FontAwesomeIcon icon={faGift} /> %70 znizki
							</span>{' '}
							Dla następnych 100 osób!
						</p>
						<div className="reviews-stars">
							<div className="avatars-stars">
								<img src={pfp1} alt="" className="img-first" />
								<img src={pfp2} alt="" />
								<img src={pfp3} alt="" />
								<img src={pfp4} alt="" />
								<img src={pfp5} alt="" />
							</div>
							<div className="review-stars">
								<div className="stars-reviews">
									<FontAwesomeIcon icon={faStar} className="fa-star" />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
								</div>
								<div className="stars-info-reviews">
									<span>990+</span> osób nam zaufało.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="image-hero">
					<img src={HeroIMG} alt="" />
				</div>
			</header>

			<section className="benefits">
				<div className="headline" data-aos="fade-up">
					<h2>Dlaczego My ?</h2>
				</div>
				<div className="container" data-aos="fade-up">
					<div className="benefit">
						<FontAwesomeIcon icon={faBook} className="fa-solid" />
						<h2>Dopracowany e-book!</h2>
						<p>
							Zagłęb się w wiedzę i odkryj niezwykłe treści w naszym dopracowanym ebooku. Zawiera on szczegółowe
							analizy, inspirujące przykłady i praktyczne wskazówki, które pomogą Ci osiągnąć swoje cele.
						</p>
					</div>
					<div className="benefit">
						<FontAwesomeIcon icon={faFaceLaughWink} className="fa-solid fa-sold" />
						<h2 className="h2-second">Świetne opinie!</h2>
						<p>
							{' '}
							Niezliczone pozytywne opinie potwierdzają, że nasza oferta niezawodnie spełnia oczekiwania klientów.
							Dołącz do grona zadowolonych użytkowników, którzy zyskali nie tylko wartościową wiedzę, ale także
							satysfakcję z naszych usług.
						</p>
					</div>
					<div className="benefit">
						<FontAwesomeIcon icon={faDollarSign} className="fa-solid" />
						<h2>Niska cena!</h2>
						<p>
							Oferujemy <span>najlepszą</span> wartość w przystępnej cenie. Nie musisz wydawać fortuny, aby skorzystać z
							naszej <span>wysokiej</span> jakości treści i usług. Nasza niska cena sprawia, że nasza oferta staje się
							jeszcze bardziej atrakcyjna dla Ciebie.
						</p>
					</div>
				</div>
			</section>

			<section className="reviews-section" id="reviews">
				<div className="headline" data-aos="fade-up">
					<h2>Potrzebujesz dowodu?</h2>
				</div>
				<div className="container-reviews" data-aos="fade-up">
					<img src={review1} alt="" />
					<img src={review2} alt="" />
					<img src={review3} alt="" />
					<img src={review4} alt="" />
					<img src={review5} alt="" />
					<img src={review6} alt="" />
					<img src={review7} alt="" />
				</div>
			</section>

			<section className="pricing" id="pricing">
				<div className="headline" data-aos="fade-up">
					<h2>Gotowy wejść na wyższy poziom?</h2>
					<p>
						<span>
							<MySVG className="graduationhat" /> 990+{' '}
						</span>{' '}
						Tyle osób nam zaufało, będziesz następny?
					</p>
				</div>
				<div className="ebooks" data-aos="fade-up">
					<div className="e-book">
						<div className="background-title">
							<h3 className="title">#1 Standrard </h3>
						</div>
						<h3>Ćwiczenia </h3>
						<h2 className="pricetag2">42.40 zł</h2>
						<h2 className="pricetag">24.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(24.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Rozpisane ćwiczenia skupione na pobudzeniu hormonu wzrostu naturalnie</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
						<h4 className="feature-title">Bonusy</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Wariant planu treningowego bez sprzętu.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
					</div>

					<div className="e-book e-book-main">
						<div className="information-popular">
							<h4>Najczęściej wybierany</h4>
						</div>
						<div className="background-title">
							<h3 className="title">#2 Premium </h3>
						</div>
						<h3>Ćwiczenia + Dieta</h3>
						<h2 className="pricetag2">76.00 zł</h2>
						<h2 className="pricetag">37.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(37.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Rozpisane ćwiczenia skupione na pobudzeniu hormonu wzrostu naturalnie</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Dieta, najlepsze posiłki oraz produkty spożywcze.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
						<h4 className="feature-title">Bonusy</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Wariant planu treningowego bez sprzętu.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Przykładowa lista zakupów.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>-</p>
						</div>
					</div>

					<div className="e-book">
						<div className="background-title">
							<h3 className="title">#3 Ultimate </h3>
						</div>
						<h3>Ćwiczenia + Dieta + Wskazówki</h3>
						<h2 className="pricetag2">135.99 zł</h2>
						<h2 className="pricetag">79.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(79.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Rozpisane ćwiczenia skupione na pobudzeniu hormonu wzrostu naturalnie</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Dieta, najlepsze posiłki oraz produkty spożywcze.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Najbardziej optymalny rozkład snu i regeneracji.</p>
						</div>
						<h4 className="feature-title">Bonusy</h4>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Wariant planu treningowego bez sprzętu.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Przykładowa lista zakupów.</p>
						</div>
						<div className="feature">
							<FontAwesomeIcon icon={faStar} />

							<p>Opis suplementacji.</p>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}

export default Home
