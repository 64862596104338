import React from 'react'
import { motion } from 'framer-motion'
import { CheckCircle, Clock, XCircle } from 'lucide-react'
import { useNavigate } from 'react-router-dom' // Import useNavigate

const glassMorphism = {
	background: 'rgba(23, 23, 23, 0.7)',
	backdropFilter: 'blur(10px)',
	borderRadius: '10px',
	border: '1px solid rgba(255, 255, 255, 0.1)',
}

export const SuccessPage = () => {
	const navigate = useNavigate() // Use the hook for navigation

	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
				color: '#ffffff',
				fontFamily: "'Inter', sans-serif",
			}}>
			<motion.div
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}
				style={{
					...glassMorphism,
					padding: '3rem',
					textAlign: 'center',
					maxWidth: '400px',
					width: '100%',
				}}>
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}>
						
					<CheckCircle size={80} color="#4CAF50" />
				</motion.div>
				<h1 style={{ fontSize: '2.5rem', margin: '1rem 0' }}>Sukces!</h1>
				<p style={{ fontSize: '1.1rem', color: '#cccccc' }}>Ebook został wysłany na twojego emaila! (Sprawdź spam!)</p>
				<motion.button
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')} // Redirect on click
					style={{
						marginTop: '2rem',
						padding: '0.8rem 1.5rem',
						fontSize: '1rem',
						background: '#4CAF50',
						color: 'white',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
					}}>
					Strona główna
				</motion.button>
			</motion.div>
		</div>
	)
}

export const PendingPage = () => {
	const navigate = useNavigate() // Use the hook for navigation

	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
				color: '#ffffff',
				fontFamily: "'Inter', sans-serif",
			}}>
			<motion.div
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}
				style={{
					...glassMorphism,
					padding: '3rem',
					textAlign: 'center',
					maxWidth: '400px',
					width: '100%',
				}}>
				<motion.div animate={{ rotate: 360 }} transition={{ repeat: Infinity, duration: 2, ease: 'linear' }}>
					<Clock size={80} color="#FFC107" />
				</motion.div>
				<h1 style={{ fontSize: '2.5rem', margin: '1rem 0' }}>Przetwarzanie...</h1>
				<p style={{ fontSize: '1.1rem', color: '#cccccc' }}>Twoja płatność jest cały czas przetwarzana...</p>
				<div style={{ marginTop: '2rem' }}>
					<motion.div
						initial={{ width: 0 }}
						animate={{ width: '100%' }}
						transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
						style={{
							height: '4px',
							background: 'linear-gradient(90deg, #FFC107, #FF9800)',
							borderRadius: '2px',
						}}
					/>
				</div>
				<motion.button
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')} // Redirect on click
					style={{
						marginTop: '2rem',
						padding: '0.8rem 1.5rem',
						fontSize: '1rem',
						background: '#FFC107',
						color: 'white',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
					}}>
					Strona główna
				</motion.button>
			</motion.div>
		</div>
	)
}

export const FailurePage = () => {
	const navigate = useNavigate() // Use the hook for navigation

	return (
		<div
			style={{
				minHeight: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: 'linear-gradient(45deg, #1a1a1a, #2a2a2a)',
				color: '#ffffff',
				fontFamily: "'Inter', sans-serif",
			}}>
			<motion.div
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}
				style={{
					...glassMorphism,
					padding: '3rem',
					textAlign: 'center',
					maxWidth: '400px',
					width: '100%',
				}}>
				<motion.div
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}>
					<XCircle size={80} color="#F44336" />
				</motion.div>
				<h1 style={{ fontSize: '2.5rem', margin: '1rem 0' }}>Oops!</h1>
				<p style={{ fontSize: '1.1rem', color: '#cccccc' }}>Coś poszło nie tak... spróbój ponownie!</p>
				<motion.button
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')} // Redirect on click
					style={{
						marginTop: '2rem',
						padding: '0.8rem 1.5rem',
						fontSize: '1rem',
						background: '#F44336',
						color: 'white',
						border: 'none',
						borderRadius: '5px',
						cursor: 'pointer',
					}}>
					Strona główna
				</motion.button>
			</motion.div>
		</div>
	)
}
