import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './InfoPage.css' // Assuming you have this CSS file for custom styles

function InfoPage() {
	const [email, setEmail] = useState('')
	const [emailConfirm, setEmailConfirm] = useState('')
	const [emailError, setEmailError] = useState('')
	const navigate = useNavigate()

	const validateEmail = email => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		return re.test(String(email).toLowerCase())
	}

	const handleEmailChange = e => {
		setEmail(e.target.value)
		if (emailConfirm && e.target.value !== emailConfirm) {
			setEmailError('Emails do not match')
		} else {
			setEmailError('')
		}
	}

	const handleEmailConfirmChange = e => {
		setEmailConfirm(e.target.value)
		if (email && e.target.value !== email) {
			setEmailError('Emails do not match')
		} else {
			setEmailError('')
		}
	}

	const handleSubmit = async event => {
		event.preventDefault()

		if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address')
			return
		}

		if (email !== emailConfirm) {
			setEmailError('Emails do not match')
			return
		}

		const amount = localStorage.getItem('selectedAmount')

		try {
			const response = await axios.post('https://titanmethod.shop/api/pay', {
				email,
				amount,
			})
			const { url } = response.data
			window.location.href = url // Redirect to HotPay
		} catch (error) {
			console.error('Error initiating payment', error.message)
		}
	}

	return (
		<div className="info-page-container">
			<div className="form-wrapper">
				<h1 className="form-title">Na ten adres email wyślemy E-booka</h1>
				<form onSubmit={handleSubmit} className="form">
					<div className="form-group">
						<label htmlFor="email" className="form-label">
							Adres Email
						</label>
						<input
							id="email"
							name="email"
							type="email"
							value={email}
							onChange={handleEmailChange}
							required
							className="form-input"
						/>
					</div>
					<div className="form-group">
						<label htmlFor="emailConfirm" className="form-label">
							Potwierdź Adres Email
						</label>
						<input
							id="emailConfirm"
							name="emailConfirm"
							type="email"
							value={emailConfirm}
							onChange={handleEmailConfirmChange}
							required
							className="form-input"
						/>
						{emailError && <p className="error-text">{emailError}</p>}
					</div>
					<button type="submit" className="form-button">
						Kontynuuj
					</button>
				</form>
			</div>
		</div>
	)
}

export default InfoPage
